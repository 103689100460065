import React, { Component } from 'react'

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import "./info.css";

class Info extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    const { close } = this.props;
    return (
      <div id="info" className="modal-body">
        <div className="close" onClick={()=>{console.log("close"); close()}}><CloseIcon/></div>
        <SimpleTabs />
      </div>
    )
  }
}


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example"
          centered
        >
          <Tab label="HOW TO FEST" {...a11yProps(0)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <VerticalTabs />
      </TabPanel>
      <TabPanel value={value} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
    </div>
  );
}

export default Info


function VTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        children
      )}
    </div>
  );
}

VTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function Va11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const VuseStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 5,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 400,
  },
  tabs: {
    borderRight: `1px solid #141414`,
  },
}));

function VerticalTabs() {
  const classes = VuseStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

        // variant="scrollable"
  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        <Tab className="vtab-button" label="EXPLORE!" {...Va11yProps(0)} />
        <Tab className="vtab-button" label="BUY A TICKET" {...Va11yProps(1)} />
        <Tab className="vtab-button" label="ENJOY FILMS" {...Va11yProps(2)} />
        <Tab className="vtab-button" label="WATCH LIVE EVENTS" {...Va11yProps(3)} />
        <Tab className="vtab-button" label="JOIN THE CONVERSATION" {...Va11yProps(4)} />
      </Tabs>
      <VTabPanel className="vtab" value={value} index={0}>
        <div class="tab-wrapper">
        <div class="tab-text">
        Explore our map! What will you find?
        </div>
        <div class="tab-image">
          <img alt="mapfull" src="/images/mapv2c/map_full.png"/>
        </div>
        </div>
      </VTabPanel>
      <VTabPanel className="vtab" value={value} index={1}>
        <div class="tab-wrapper">
        <div class="tab-text">
        Buy a ticket to enjoy our hand-picked films!
        </div>
        <div class="tab-image small">
          <img alt="tickets" src="/images/mapv2c/tickets.png"/>
        </div>
        </div>
      </VTabPanel>
      <VTabPanel className="vtab" value={value} index={2}>
        <div class="tab-wrapper">
        <div class="tab-text">
        Sit back, relax and enjoy films from the comfort of your home.
        </div>
        <div class="tab-image small">
          <img alt="movies" src="/images/mapv2c/movies.png"/>
        </div>
        </div>
      </VTabPanel>
      <VTabPanel className="vtab" value={value} index={3}>
        <div class="tab-wrapper">
        <div class="tab-text">
        Watch our live events with guest speakers!
        </div>
        <div class="tab-image small">
          <img alt="live" src="/images/mapv2c/live.png"/>
        </div>
        </div>
      </VTabPanel>
      <VTabPanel className="vtab" value={value} index={4}>
        <div class="tab-wrapper">
        <div class="tab-text">
        Join the conversation at our cafe!
        </div>
        <div class="tab-image small">
          <img alt="chat" src="/images/mapv2c/chat.png"/>
        </div>
        </div>
      </VTabPanel>
    </div>
  );
}
