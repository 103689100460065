import React, { Component } from "react";
// import ReactDOM from 'react-dom';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

import Modal from '@material-ui/core/Modal';

import Backdrop from '@material-ui/core/Backdrop';
import Grow from '@material-ui/core/Grow';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';

import Events from './components/events.js'
import Info from './components/info.js'
import Live from './components/live.js'
import Twitch from './components/twitch.js'
import Movies from './components/movies.js'
import Tickets from './components/tickets.js'
import Chat from './components/chat.js'
import DOMAINPREFIX from "./globals.js"

import "./App.css";


const App = () => {
  return (
   <BrowserRouter>
       <Switch>
           <Route exact path='/'>
               <Home />
           </Route>
       </Switch>
   </BrowserRouter>
  );
};

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapLoaded: false,
      loaded: false,
      fetching: false,
      baseData: {
        authorized: false,
        movies: [],
        events: [],
      },

      modal: this.defaultModalState(),
    }
  }

  handleImageLoaded() {
    this.setState({ mapLoaded: true });
  }

  handleImageErrored() {
    console.log("MAP LOAD ERROR");
  }

  defaultModalState = () => {
    return {
      info: false,
      tickets: false,
      chat: false,
      movies: false,
      live: false,
      award: false,
      shrine: false,
      twitch: false,
    }
  }

  getDataDump = () => {
    function handleErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    }

    this.setState({
      loaded: false,
      fetching: true,
    });
    fetch(DOMAINPREFIX + '/dump')
      .then(handleErrors)
      .then(response => response.json())
      .then(json => {
        this.setState({
          loaded: true,
          fetching: false,
          baseData: json,
        });
        console.log("baseData:", json);
      })
      .catch(error => {
        this.setState({
          loaded: false,
          fetching: false,
        });
        console.log(error)
      });
  }

  handleModalOpen = (name, anchor=false) => {
    let s = this.state;
    if (s.modal[name]) {
      return;
    }
    s.modal = this.defaultModalState();
    s.modal[name] = true;
    this.setState({
      s,
    });
  }

  handleModalClose = (name) => {
    let s = this.state;
    if (!s.modal[name]) {
      return;
    }
    s.modal[name] = false;
    window.location = "/#map";
    this.setState({
      s,
    });
  }

  componentDidMount() {
    // Check to see if this is a redirect back from Checkout
    const hash = window.location.hash;
    switch(hash) {
      default:
        break;
      case "#tickets":
        this.handleModalOpen("tickets", false);
        break;
      case "#success":
        this.handleModalOpen("tickets", false);
        break;
      case "#cancel":
        this.handleModalOpen("tickets", false);
        break;
      case "#free":
        this.handleModalOpen("tickets", false);
        break;
      case "#testlive":
        this.handleModalOpen("twitch", false);
        break;
    }

    this.getDataDump();
  }

  render() {
    let mapClasses = "";
    let spinnerClasses = "spinner hidden";
    if (!this.state.mapLoaded) {
      mapClasses = "hidden";
      spinnerClasses = "spinner";
    }
    return (
        <div>
            <div className={spinnerClasses}><div className="lds-facebook"><div></div><div></div><div></div></div></div>
            <div id="logo" onClick={()=>{window.location="https://japanwebfest.com"}}>
              <img alt="logo" src="/images/logo_color.png"/>
            </div>
            <div id="social">
              <a href="https://www.instagram.com/japanwebfest"> <InstagramIcon fontSize="large"/></a>
              <a href="https://www.facebook.com/japanwebfest"> <FacebookIcon fontSize="large"/> </a>
            </div>
            <div id="map" className={mapClasses}>
              <img alt="map" className="map-image map-bg"
                onLoad={this.handleImageLoaded.bind(this)}
                onError={this.handleImageErrored.bind(this)}
                src="/images/mapv2c/map.png"/>
              <div className="map-items">
                <div className="map-btn map-info" onClick={()=>this.handleModalOpen("info")}>
                  <div className="map-label"> INFO</div>
                  <img alt="info" src="/images/mapv2c/info.png" />
                </div>
                <div className="map-btn map-tickets" onClick={()=>this.handleModalOpen("tickets")}>
                  <div className="map-label"> TICKETS</div>
                  <img alt="tickets" src="/images/mapv2c/tickets.png" />
                </div>
                <div className="map-btn map-movies" onClick={()=>this.handleModalOpen("movies")} >
                  <div className="map-label"> CINEMA</div>
                  <img alt="movies" src="/images/mapv2c/movies.png" />
                </div>
                <div className="map-btn map-awards" onClick={()=>this.handleModalOpen("award")} >
                  <div className="map-label"> AWARDS</div>
                  <img alt="awards" src="/images/mapv2c/award.png" />
                </div>
                <div className="map-btn map-live" onClick={()=>this.handleModalOpen("live")} >
                  <div className="map-label"> LIVE EVENTS</div>
                  <img alt="live" src="/images/mapv2c/live.png" />
                </div>
                <div className="map-btn map-chat" onClick={()=>this.handleModalOpen("chat")} >
                  <div className="map-label"> CAFE</div>
                  <img alt="chat" src="/images/mapv2c/chat.png" />
                </div>
                <div className="map-btnt map-fuji">
                  <img alt="chat" src="/images/mapv2c/fuji.png" />
                </div>
                <div className="map-btnt map-shrine">
                  <img alt="chat" src="/images/mapv2c/shrine.png" />
                </div>
              </div>
              <img alt="map" className="map-image map-fg"
                src="/images/mapv2c/map_fg.png"/>
            </div>
            <Events events={this.state.baseData.events} handleModalOpen={this.handleModalOpen}/>
            <Modal open={this.state.modal.info} onClose={()=>this.handleModalClose("info")}
              className="centered-modal"
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                  timeout: 500, }}
            >
              <Grow in={this.state.modal.info} timeout={300}>
                <Info close={() => {this.handleModalClose("info")}}/>
              </Grow>
            </Modal>
            <Modal open={false} onClose={()=>this.handleModalClose("live")}
              className="centered-modal"
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                  timeout: 500, }}
            >
              <Grow in={this.state.modal.live} timeout={300}>
                <Twitch openTickets={()=>{this.handleModalOpen("tickets")}} authorized={this.state.baseData.authorized} close={() => {this.handleModalClose("live")}}/>
              </Grow>
            </Modal>

            <Modal open={false} onClose={()=>this.handleModalClose("award")}
              className="centered-modal"
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                  timeout: 500, }}
            >
              <Grow in={this.state.modal.award} timeout={300}>
                <Twitch openTickets={()=>{this.handleModalOpen("tickets")}} authorized={this.state.baseData.authorized} close={() => {this.handleModalClose("award")}}/>
              </Grow>
            </Modal>

            <Modal open={this.state.modal.twitch} onClose={()=>this.handleModalClose("twitch")}
              className="centered-modal"
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                  timeout: 500, }}
            >
              <Grow in={this.state.modal.award} timeout={300}>
                <Twitch openTickets={()=>{this.handleModalOpen("tickets")}} authorized={this.state.baseData.authorized} close={() => {this.handleModalClose("twitch")}}/>
              </Grow>
            </Modal>

            <Modal open={this.state.modal.shrine} onClose={()=>this.handleModalClose("shrine")}
              className="centered-modal"
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                  timeout: 500, }}
            >
              <Grow in={this.state.modal.shrine} timeout={300}>
                <Live close={() => {this.handleModalClose("shrine")}}/>
              </Grow>
            </Modal>

            <Modal open={this.state.modal.movies} onClose={()=>this.handleModalClose("movies")}
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                  timeout: 500, }}
            >
              <Grow in={this.state.modal.movies} timeout={300}>
                <Movies openTickets={()=>{this.handleModalOpen("tickets")}} authorized={this.state.baseData.authorized} openTickets={()=>{this.handleModalOpen("tickets")}} movies={this.state.baseData.movies} close={() => {this.handleModalClose("movies")}}/>
              </Grow>
            </Modal>

            <Modal open={false} onClose={()=>this.handleModalClose("tickets")}
              className="centered-modal"
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                  timeout: 500, }}
            >
              <Grow in={this.state.modal.tickets} timeout={300}>
                <Tickets
                  authorized={this.state.baseData.authorized}
                  getDataDump={this.getDataDump}
                  fetching={this.state.fetching}
                  close={() => {this.handleModalClose("tickets")}}
                />
              </Grow>
            </Modal>

            <Modal open={this.state.modal.chat} onClose={()=>this.handleModalClose("chat")}
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                  timeout: 500,
              }}
            >
              <Grow in={this.state.modal.chat} timeout={300}>
                <Chat close={() => {this.handleModalClose("chat")}} />
              </Grow>
            </Modal>
        </div>
    );
  }
}

export default App;
