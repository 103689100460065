import React, { Component } from 'react'
import Countdown from 'react-countdown';

import './events.css'

class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  getNextEvent = (events) => {
    let d = new Date();
    let event = null;

    let events_sorted = events.sort((a, b) => b.start_time - a.start_time);
    if (events_sorted.length === 0) { return event; }

    for (let i = 0; i < events_sorted.length; i++) {
      let e = events_sorted[i]
      // start time
      let es = new Date(e.start_time);
      // end time
      let ee = new Date(es)
      ee.setTime(ee.getTime() + e.len_hours*60*60*1000);

      let before = d < es;
      let during = (d > es && d < ee);

      if (before) {
        return {
          name: e.name,
          countdown: true,
          start_time: es,
          modal: e.modal,
        }
      }
      if (during) {
        return {
          name: e.name,
          countdown: false,
          start_time: es,
          modal: e.modal,
        }
      }
    }

    return event;
  }

  render() {
    const {events, handleModalOpen} = this.props;
    let e = this.getNextEvent(events);
    if (e === null) {
      return null;
    }
    return (
      <div id="events" onClick={()=>handleModalOpen(e.modal)}>
        <div className="events-name">{e.name}</div>
        <div className="events-time-wrapper">
          { e.countdown ?
            <div className="events-countdown">
              <Countdown date={e.start_time} />
            </div> :
            <div className="events-now">Happening Now!</div>
          }
        </div>
      </div>
    )
  }
}

export default Events
