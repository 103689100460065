import React, { Component } from 'react'

import CloseIcon from '@material-ui/icons/Close';

import './chat.css'

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    const {close} = this.props;
    return (
      <div id="chat" className="modal-body">
        <div className="close close-bg" onClick={()=>{console.log("close"); close()}}><CloseIcon/></div>
        <iframe src="https://spatial.chat/s/japanwebfest" style={{height: "100%", width: "99%"}} allow="camera;microphone" title="chat"></iframe>
      </div>
    )
  }
}

export default Chat
