import React, { Component } from 'react'

import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ReactCodeInput from 'react-code-input';
import { loadStripe } from "@stripe/stripe-js";

import DOMAINPREFIX, {STRIPEPUB} from "../globals.js"
import './tickets.css';

const stripePromise = loadStripe(STRIPEPUB);


class Tickets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "BUY",
      pin: "",
      email: "",
      coupon: "",

      showCoupon: false,
      stripeLoading: false,
      resending: false,
      resendError: undefined,
      resendMessage: undefined,
    }

    // this.onResendSubmit = this.onResendSubmit.bind(this);
    // this.onEmailChange = this.onEmailChange.bind(this);
  }

  // BUY
  // INPUT
  // RESET
  setMode = (name) => {
    if (this.state.mode === name) {
      return;
    }
    this.setState({
      mode: name
    });
  }

  componentDidMount() {
    const hash = window.location.hash;
    switch(hash) {
      default:
        break;
      case "#success":
        this.setMode("INPUT");
        break;
      case "#cancel":
        this.setMode("BUY");
        break;
      case "#free":
        this.setMode("FREE");
        break;
    }
  }

  onEmailChange = (event) => {
    // TODO austin validate
    this.setState({email: event.target.value});
  }

  onCouponChange = (event) => {
    // TODO austin validate
    this.setState({coupon: event.target.value});
    document.cookie = `ticket_discount=${event.target.value}; expires=Thu, 18 Dec 2030 12:00:00 UTC`;
  }

  onResendSubmit = (event) => {
    event.preventDefault();

    function handleErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    }

    this.setState({
      resending: true,
      resendMessage: undefined,
      resendError: undefined,
    });

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: this.state.email })
    };
    // fetch('http://localhost:8080/resend', requestOptions)
    fetch(DOMAINPREFIX + '/resend', requestOptions)
      .then(handleErrors)
      .then(response => response.json())
      .then(json => {
        if (json.success) {
          this.setState({
            resending: false,
            resendMessage: "Successfully resent the ticket! Please check your email",
            resendError: undefined,
          });
        } else {
          this.setState({
            resending: false,
            resendMessage: undefined,
            resendError: "We didn't find a ticket for that email",
          });
        }
      })
      .catch(error => {
        this.setState({
          resending: false,
          resendMessage: undefined,
          resendError: "Looks like there was a problem, please try again later",
        });
        console.log(error)
      });
  }

  onFreeSubmit = (event) => {
    event.preventDefault();

    function handleErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    }

    this.setState({
      resending: true,
      resendMessage: undefined,
      resendError: undefined,
    });

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: this.state.email })
    };
    fetch(DOMAINPREFIX + '/free', requestOptions)
      .then(handleErrors)
      .then(response => response.json())
      .then(json => {
        if (json.success) {
          this.setState({
            resending: false,
            resendMessage: "Successfully sent your ticket! Please check your email",
            resendError: undefined,
          });
        } else {
          this.setState({
            resending: false,
            resendMessage: undefined,
            resendError: "Invalid coupon code, please check your code and try again",
          });
        }
      })
      .catch(error => {
        this.setState({
          resending: false,
          resendMessage: undefined,
          resendError: "Looks like there was a problem, please try again later",
        });
        console.log(error)
      });
  }


  onPinChange = (pin) => {
    if (pin.length === 6) {
      this.setState({pin: pin});
      document.cookie = `ticket_id=${pin}; expires=Thu, 18 Dec 2030 12:00:00 UTC`;
    }
  }

  renderReset = (free) => {
    const {resendError, resendMessage} = this.state;

    let onClick = this.onResendSubmit;
    if (free) {
      onClick = this.onFreeSubmit;
    }

    const resender = (
      <>
      { free ? <h1>Please input your email to get your free ticket!</h1> : null }
      <div className="ticket-row">
        <TextField id="reset-email" label="Email" variant="outlined" value={this.state.email} onChange={this.onEmailChange} style={{"marginRight": "10px"}}/>
        <Button type="submit" variant="contained" color="primary" onClick={onClick}
          disabled={this.state.resending}
        > { free ? "Get Ticket" : "Resend Email" } </Button>
      </div>
      </>
    );

    if (resendError !== undefined) {
      return (
        <>
          { resender }
          <div className="ticket-row">
            <h3> { resendError } </h3>
          </div>
        </>
      );
    }
    if (resendMessage !== undefined) {
      return (
        <>
          <div className="ticket-row">
            <h3> { resendMessage } </h3>
            <Button variant="outlined" color="primary" onClick={()=>this.setMode("INPUT")}> Input Ticket </Button>
          </div>
        </>
      );
    }

    return resender;
  }

  loadStripeSet = () => {
    this.setState({
      stripeLoading: true,
    })
  }

  handleCoupon = () => {
    this.setState({
      showCoupon: true,
    })
  }
  closeCoupon = () => {
    this.setState({
      showCoupon: false,
    })
  }

  handleBuyClick = async (event) => {
    const stripe = await stripePromise;
    const response = await fetch(DOMAINPREFIX + "/create-checkout-session", {
      method: "POST",
    });

    if (response.redirected) {
      this.setState({
        stripeLoading: false,
      });
      this.setMode("FREE");
    }

    const session = await response.json();
    console.log(response, session);
    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });
    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
      // TODO austin
    }
  };

  render() {
    const { authorized, getDataDump, fetching, close } = this.props;
    const { mode, pin } = this.state;

    let key = mode;
    if (authorized) { key = "OKAY"; }

    let modeMap = {
      "OKAY": (
        <div id="tickets-okay">
          <img alt="logo" className="ticket-logo" src="/images/logo_black.png"/>
          <h1> LOGGED IN </h1>
        </div>
      ),

      "BUY": (
        <div id="tickets-buy">
            <img alt="logo" className="ticket-logo" src="/images/logo_black.png"/>
            <div className="tickets-buy-inner">
              <h1>Japan Web Fest 2021</h1>
              <div className="info clearfix">
              <h1>TICKET X 1</h1>
              </div>
              {
                !this.state.showCoupon ?
                <div className='info clearfix ticket-price'>
                  <h2 style={{"marginRight": "40px"}}>¥1,000</h2>
                  <Stripe handleBuyClick={this.handleBuyClick} setMode={this.setMode} handleCoupon={this.handleCoupon} loadStripeSet={this.loadStripeSet} stripeLoading={this.state.stripeLoading}/>
                </div> :
                <div className="ticket-coupon">
                  <TextField size="small" id="coupon-code" label="coupon code" variant="outlined" value={this.state.coupon} onChange={this.onCouponChange}/>
                  <Button className="coupon-buy" type="submit" variant="contained" color="primary" onClick={this.handleBuyClick}
                  > BUY NOW </Button>
                  <div className="coupon-close" onClick={this.closeCoupon}><CloseIcon/></div>
                </div>
              }
            </div>
          <div className='ticket-footer'>
            <Button size="small" variant="text" color="primary" onClick={()=>this.setMode("INPUT")}> I already have my ticket. </Button>
            <Button size="small" variant="text" color="secondary" onClick={()=>this.setMode("RESET")} > I lost my ticket. </Button>
          </div>
        </div>
      ),

      "INPUT": (
        <div id="tickets-input">
          <h1> Thank you! Please check your email for a ticket.</h1>
          <div className="ticket-row">
            <ReactCodeInput type='text' fields={6} onChange={this.onPinChange}/>
            <Button
              variant="contained"
              color="primary"
              onClick={getDataDump}
              disabled={fetching || pin.length < 6}>
              submit
            </Button>
          </div>
          <div className='ticket-footer'>
            <Button variant="text" color="primary" onClick={()=>this.setMode("BUY")} > Back </Button>
            <Button variant="text" color="secondary" onClick={()=>this.setMode("RESET")} > I lost my ticket. </Button>
          </div>
        </div>
      ),

      "RESET": (
        <div id="tickets-reset">
          { this.renderReset(false) }
          <div className='ticket-footer'>
            <Button variant="text" color="primary" onClick={()=>this.setMode("BUY")} > Back </Button>
          </div>
        </div>
      ),

      "FREE": (
        <div id="tickets-free">
          { this.renderReset(true) }
          <div className='ticket-footer'>
            <Button variant="text" color="primary" onClick={()=>this.setMode("BUY")} > Back </Button>
          </div>
        </div>
      ),
    }

    return (
      <div id="tickets" className="box" tabIndex={-1}>
        <div className="close" onClick={()=>{console.log("close"); close()}}><CloseIcon/></div>
        <div className='inner'>
          {modeMap[key]}
        </div>
      </div>
    );
  }
}

const ProductDisplay = ({ handleClick, handleCoupon, stripeLoading }) => (
    <>
    <Button variant="contained" color="primary" id="checkout-button" className="coupon-buy" role="link" disabled={stripeLoading} onClick={handleClick}>
      {stripeLoading ? "LOADING..." : "BUY NOW"}
    </Button>
    <Button variant="contained" color="default" id="checkout-button" disabled={stripeLoading} onClick={handleCoupon}>
      COUPON
    </Button>
    </>
);

class Stripe extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    const { handleCoupon, handleBuyClick } = this.props;
    return (
      <ProductDisplay handleCoupon={handleCoupon} handleClick={()=>{this.props.loadStripeSet(); handleBuyClick()}} stripeLoading={this.props.stripeLoading}/>
    );
  }
}

export default Tickets
