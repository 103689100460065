import React, { Component } from 'react'

import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';

import {TWITCHPARENT} from "../globals.js"

import './twitch.css';

class Twitch extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    const {close, authorized, openTickets} = this.props;
    const parentStr = TWITCHPARENT;

    return (
      <div id="twitch" className="modal-body">
        <div className="close" onClick={()=>{console.log("close"); close()}}><CloseIcon/></div>
        <iframe
            src={`https://player.twitch.tv/?channel=japanwebfest${parentStr}&autoplay=true&muted=false`}
            height="500"
            width="60%"
            allowfullscreen="true"
            frameBorder="0"
            >
        </iframe>
        <div className="sched">
          <div className="sched-header">2/21 Schedule Day 2</div>
          <div className="sched-sub">Times in JST</div>
          <div className="sched-item">
            <div className="item-row">
              <div className="item-time">2:30 PM</div>
              <div className="item-title">OPENING CEREMONY</div>
            </div>
          </div>
          <div className="sched-item">
            <div className="item-row">
              <div className="item-time">2:40 PM</div>
              <div className="item-title">WEBINAR</div>
            </div>
            <div className="item-sub">Fighting Corona: How to Use Audience Data Correctly For Film Marketing. A webinar by GRUVI</div>
          </div>
          <div className="sched-item">
            <div className="item-row">
              <div className="item-time">3:50 PM</div>
              <div className="item-title">DOCUMENTARY FILMMAKING</div>
            </div>
            <div className="item-sub">Documentary Filmmaking from a Criminologist’s Point of View with Alex Rodal</div>
          </div>
          <div className="sched-item">
            <div className="item-row">
              <div className="item-time">4:45 PM</div>
              <div className="item-title">PITCHING CONTEST</div>
            </div>
          </div>
          <div className="sched-item">
            <div className="item-row">
              <div className="item-time">5:00 PM</div>
              <div className="item-title">AWARD CEREMONY</div>
            </div>
          </div>
          <div className="sched-item">
            <div className="item-row">
              <div className="item-time">5:06 PM</div>
              <div className="item-title">CLOSING NOTE</div>
            </div>
          </div>
          <div className="sched-item">
            <div className="item-row">
              <div className="item-time">6:10 PM</div>
              <div className="item-title">AFTER PARTY AT CAFE</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Twitch

        // <img alt="logo" src="/images/logo_white.png"/>
        // <div className="coming-soon-body">
          // <h1>COMING SOON</h1>
        // </div>
