import React, { Component } from 'react'

import CloseIcon from '@material-ui/icons/Close';

import './live.css';

class Live extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    const {close} = this.props;
    return (
      <div id="live" className="modal-body coming-soon">
        <div className="close" onClick={()=>{console.log("close"); close()}}><CloseIcon/></div>
        <img alt="logo" src="/images/logo_white.png"/>
        <div className="coming-soon-body">
          <h1>COMING SOON</h1>
        </div>
      </div>
    )
  }
}

export default Live

