import React from 'react';

// import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import IconCross from './../Icons/IconCross';

import ReactJWPlayer from "react-jw-player";

import './Content.scss';

const Content = ({ movie, onClose, authorized, openTickets }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let jwp_player = 'MXWbyWEd';
  let jwp_alt = '5GUnpWeL';
  if (movie.exception) {
    jwp_player = 'EKRmUO4P';
    jwp_alt = '33719540';
  }

  return (
    <div key={movie.id} className="content">
      <div className="content__background">
        <div className="content__background__shadow" />
        <div
          className="content__background__image"
          style={{ 'backgroundImage': `url(${movie.imageBg})` }}
        />
      </div>
      <div className="content__area">
        <div className="content__area__container">
          <div className="content__title">{movie.title}</div>
          <div className="content__description">
            { movie.description }
          </div>
          <div className="content__actions">
            { false ?
              <div className="content__action"
                onClick={()=> {
                  if (authorized) { handleOpen() } else { openTickets() } }}>
                  <PlayArrowIcon /> Play </div>
              : null
            }
            <div className="content__duration">{movie.duration}</div>
           <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              className="centered-modal"
            >
              <div className="videomodal-body">
              <ReactJWPlayer
                playerId={movie.id}
                playerScript={`https://cdn.jwplayer.com/libraries/${jwp_player}.js`}
                file={`https://content.jwplatform.com/videos/${movie.jwp_id}-${jwp_alt}.mp4`}
                image={movie.background}
              />
              </div>
            </Modal>
          </div>
        </div>
        <button className="content__close" onClick={onClose}>
          <IconCross />
        </button>
      </div>
    </div>
  )
}

export default Content;
